<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ตั้งค่าธุรกิจ / สาขา</div>
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon>เพิ่มสาขา</v-btn
      >
    </v-toolbar>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      :loading-text="$table_loading_text"
      item-key="_id"
      @click:row="edit"
    >
      <!-- <template v-slot:[`item.status`]="{ item }">
        <menu-ship small v-model="item.status"/>
      </template> -->
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.is_split_bill`]="{ item }">
        <v-icon v-if="item.is_split_bill">mdi-check</v-icon>
        <v-icon v-else>mdi-close</v-icon>
      </template>
      <template v-slot:[`item.split_bill_name`]="{ item }">
        {{ item.split_bill_data.name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Detail from '@/views/pages/setting/user/Detail.vue';

export default {
  components: {
    Detail,
  },
  data: () => ({
    selected: [],
    loading: true,
    pageCount: 0,
    form: {
      setup_wizard: 0,
      bank_account: [],
      branch_name: '',
      company_branch: '',
      // company_group_id: "",
      document_setting: {
        credit_note: { header: '', note: '' },
        debit_note: { header: '', note: '' },
        invoice: { header: '', note: '' },
        quotation: { header: '', note: '' },
        receipt: { header: '', note: '' },
        tax_invoice: { header: '', note: '' },
      },
      email: '',
      facebook: '',
      geopoint: { lat: '', lng: '' },
      is_split_bill: false,
      line_id: '',
      location: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
      logo_url: '',
      qr_code_payment: '',
      name_for_receipt: '',
      open_hours: {
        friday: { open: false, time: [{ end: '', start: '' }] },
        monday: { open: false, time: [{ end: '', start: '' }] },
        saturday: { open: false, time: [{ end: '', start: '' }] },
        sunday: { open: false, time: [{ end: '', start: '' }] },
        thursday: { open: false, time: [{ end: '', start: '' }] },
        tuesday: { open: false, time: [{ end: '', start: '' }] },
        wednesday: { open: false, time: [{ end: '', start: '' }] },
      },
      phone_number: '',
      setting: {
        print_one_page: '',
        enable_product_merging: false,
        receipt_printing_format: 0,
        domain_for_qr: '',
        alert_onselect_cartype2: '',
        ban_car_province: [],
        car_tax_scrap: 0,
        cost_extension_car_tax: 0,
        cost_extension_motorbike_tax: 0,
        document_pickup_date: 0,
        document_pickup_time: '',
        merging_subcategory: [],
        ges_inspect_alert: false,
        letter_alert: false,
        reduce_bill_scrap: false,
        sms_alert: false,
        starting_province: '',
        tel_alert: false,
        third_insurance_tax_scrap: 0,
        CT1_3rdinsurance_express: '',
        CT2_3rdinsurance_express: '',
        CT3_3rdinsurance_express: '',
        CT4_3rdinsurance_express: '',
        other_3rdinsurance_express: '',
        qr_tracking: false,
        domain_for_qr: '',
      },
      split_bill_category: [],
      split_bill_data: {
        company_branch: '',
        email: '',
        facebook: '',
        line_id: '',
        location: {
          address: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: '',
        },
        logo_url: '',
        name: '',
        taxpayer_number: '',
        phone_number: '',
        vat_registration: false,
        is_same_as_above: false,
      },
      store_name: '',
      taxpayer_number: '',
      vat_registration: false,
    },
    headers: [
      { text: 'ลำดับ', value: 'index', align: 'center' },
      // { text: 'ชื่อสาขา', value: 'branch_name' },
      { text: 'ชื่อร้าน', value: 'store_name' },
      { text: 'ใช้งานแยกบิลบริษัท', value: 'is_split_bill', align: 'center' },
      { text: 'ชื่อบริษัทแยกบิล', value: 'split_bill_name' },
      { text: '', value: 'actions', sortable: false },
    ],
    list: [],
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
    },
  }),

  created() {
    this.getData();
  },

  methods: {
    add() {
      this.loading = true;
      this.$alertConfirm({ title: `ยืนยันการสร้างสาขา ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            this.form.setup_wizard = 1;
            let body = {
              token: this.$jwt.sign(this.form, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/branch/create_branch`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                console.log(this.form);

                this.$router.push({
                  name: 'setup-business-branch-update',
                  params: { id: res.result },
                });
              })
              .catch((err) => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
          }
          this.loading = false;
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'setting-business-branch-update',
        params: { id: item._id },
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async (result) => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ branch_id: item._id }, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/branch/delete_branch`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/branch/get_branch`, body)
        .then((res) => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    },
  },
};
</script>
